import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import type { ContentBodyType, ContentNodeType } from '../__types__';

export const useReadAndWriteContentQueryDataCache = ({ query, contentId, spaceKey }) => {
	const client = useApolloClient();
	const isSpaceAliasFFEnabled = true;
	const readAndWriteQueryCache = useCallback(
		(newAdfDoc) => {
			const isSSR = Boolean(process.env.REACT_SSR || window.__SSR_RENDERED__);

			const contentUnifiedQueryVariables = {
				contentId,
				spaceKey,
				versionOverride: null,
				isSSR,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: true,
			};

			const contentQueryResultCache = client.readQuery({
				query,
				variables: contentUnifiedQueryVariables,
			});

			const content: ContentNodeType = contentQueryResultCache?.content?.nodes?.[0] || null;
			const contentBody: ContentBodyType = content?.body?.dynamic || null;

			if (contentBody) {
				contentBody.value = JSON.stringify(newAdfDoc);
				client.writeQuery({
					query,
					variables: contentUnifiedQueryVariables,
					data: contentQueryResultCache,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query, contentId, spaceKey],
	);

	return readAndWriteQueryCache;
};
